import { ChevronRightIcon } from '@c/icons';
import Link from 'next/link';
import React from 'react';

const NavMenuSection = ({
  label,
  href,
  children,
}: {
  label: string;
  href: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex w-[28rem] flex-col px-4">
      <Link
        href={href}
        className="group flex w-full items-center gap-x-4 border-b border-zinc-400 pb-2 text-[1.6rem] font-bold capitalize hover:text-brand-secondary"
      >
        {label}

        <ChevronRightIcon />
      </Link>

      {children}
    </div>
  );
};

export default NavMenuSection;
