'use client';

import {
  BoxIcon,
  ChatFeatureIcon,
  DollarCircleIcon,
  HeartIcon,
  TruckIcon,
} from '@c/icons';
import { FaRegHourglass } from 'react-icons/fa';
import SafeImage from '@ui/SafeImage';
import { NotificationDocument } from 'models/notification';
import { useState } from 'react';
import HandPalmIcon from './assets/icons/hand-palm';
import DeliveredIcon from './assets/icons/delivered';
import { Bag } from '@c/icons/bag';

interface NotificationIconProps {
  notification: NotificationDocument;
}

const NotificationIcon = ({ notification }: NotificationIconProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  if (notification.title.toLowerCase().includes('complete')) {
    console.log({ notification });
  }

  const notificationColors = {
    secondary: 'zinc-600',
    danger: 'red-600',
    success: 'green-600',
    warning: 'yellow-600',
    info: 'blue-600',
  };

  const getNotificationIcon = () => {
    switch (notification.icon) {
      case 'eye':
        return {
          component: <DollarCircleIcon className="h-8 w-8" />,
          border: `border-${notificationColors.success}`,
          foreground: `text-${notificationColors.success}`,
        };
      case 'hourglass':
        return {
          component: <FaRegHourglass className="h-8 w-8" />,
          border: `border-${notificationColors.secondary}`,
          foreground: `text-${notificationColors.secondary}`,
        };
      case 'send':
        return {
          component: <HeartIcon className="h-8 w-8" />,
          border: `border-${notificationColors.danger}`,
          foreground: `text-${notificationColors.danger}`,
        };
      case 'close-circle':
        return {
          component: <HandPalmIcon className="h-8 w-8" />,
          border: `border-${notificationColors.danger}`,
          foreground: `stroke-${notificationColors.danger}`,
        };
      case 'card':
        return {
          component: <BoxIcon className="h-8 w-8" />,
          border: `border-${notificationColors.secondary}`,
          foreground: `text-${notificationColors.secondary}`,
        };
      case 'checkmark-circle': {
        return {
          component: <HandPalmIcon className="h-8 w-8" />,
          border: `border-${notificationColors.success}`,
          foreground: `stroke-${notificationColors.success}`,
        };
      }
      case 'airplane': {
        return {
          component: <TruckIcon className="h-8 w-8" />,
          border: 'bg-zinc-300',
          foreground: `text-${notificationColors.secondary}`,
        };
      }
      case 'cube': {
        return {
          component: <DeliveredIcon className="h-8 w-8" />,
          border: `border-${notificationColors.success}`,
          foreground: `fill-${notificationColors.success}`,
        };
      }
      case 'bag-check': {
        return {
          component: <Bag className="h-8 w-8" />,
          border: `border-${notificationColors.success}`,
          foreground: `fill-${notificationColors.success}`,
        };
      }
      case 'chatbubble-ellipses': {
        return {
          component: <ChatFeatureIcon className="h-12 w-12" />,
          border: `border-${notificationColors.secondary}`,
          foreground: `text-${notificationColors.secondary}`,
        };
      }
      default:
        return null;
    }
  };


  const notificationIcon = getNotificationIcon();

  return (
    <div className="relative flex h-16 w-16 items-center justify-center">
      {notificationIcon ? (
        <div
          className={`flex h-16 w-16 items-center justify-center rounded-full ${notificationIcon.foreground} border ${notificationIcon.border}`}
        >
          {notificationIcon.component}
        </div>
      ) : (
        notification.thumbnail && (
          <>
            {!imageLoaded && (
              <div className="absolute flex aspect-square h-16 w-16 animate-pulse rounded-full bg-gray-200" />
            )}

            <SafeImage
              src={notification.thumbnail}
              width={300}
              height={300}
              alt={notification.title}
              key={notification.thumbnail}
              className={`aspect-square h-16 w-16 rounded-full object-cover transition-opacity duration-300 ${
                imageLoaded ? 'opacity-100' : 'opacity-0'
              }`}
              onLoad={() => setImageLoaded(true)}
            />
          </>
        )
      )}
    </div>
  );
};

export default NotificationIcon;
