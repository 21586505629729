'use client';
import { ReactNode } from 'react';
import Navbar from '../Navbar';
import MainContent from './components/MainContent';
import Footer from '@c/Footer';
import { usePathname } from 'next/navigation';
interface NavbarFooterLayoutProps {
  children: ReactNode;
}
export default function NavbarFooterLayout({
  children,
}: NavbarFooterLayoutProps) {
  const pathname = usePathname();
  const excludedNavRoutes = [
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
    '/401',
    '/app/bank-connect',
    '/become-a-seller',
    '/checkout',
    '/create-listing',
    '/edit-listing',
    '/order-confirmation',
    '/verify-email',
    '/verify-id',
    '/verify-phone',
  ];
  const excludedFooterRoutes = [
    ...excludedNavRoutes,
    '/dashboard/messages',
    '/dashboard/offers',
  ];
  return (
    <>
      {!excludedNavRoutes.some((route) => pathname?.startsWith(route)) && (
        <Navbar />
      )}
      <MainContent>{children}</MainContent>
      {!excludedFooterRoutes.some((route) => pathname?.startsWith(route)) && (
        <Footer />
      )}
    </>
  );
}
